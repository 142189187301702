<ng-template #changelogModal>
  <div class="modal-content">
    <div class="modal-header modal-spacing">
      <div class="modal-title">
        <h1>{{ "interface_changelog_page_title" | translationPipe }}</h1>
      </div>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body modal-spacing">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              {{ "interface_changelog_page_version_title" | translationPipe }}
            </th>
            <th scope="col">
              {{ "interface_changelog_page_date_title" | translationPipe }}
            </th>
            <th scope="col">
              {{ "interface_changelog_page_changes_title" | translationPipe }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              {{ "interface_changelog_page_v_3_1" | translationPipe }}
            </th>
            <td class="date">
              {{ "interface_changelog_page_v_3_1_date" | translationPipe }}
            </td>
            <td>
              <ul>
                <li>
                  {{
                    "interface_changelog_page_new_materials" | translationPipe
                  }}
                  <ul>
                    <li>
                      {{
                        "interface_changelog_page_v_3_1_change_1_1"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_3_1_change_1_2"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_3_1_change_1_3"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_3_1_change_1_4"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_3_1_change_1_5"
                          | translationPipe
                      }}
                    </li>
                  </ul>
                </li>
                <li>
                  {{
                    "interface_changelog_page_new_conv_proc" | translationPipe
                  }}
                  <ul>
                    <li>
                      {{
                        "interface_changelog_page_v_3_1_change_2_1"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_3_1_change_2_2"
                          | translationPipe
                      }}
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
          <!-------FIN V3.1------>
          <tr>
            <th scope="row">
              {{ "interface_changelog_page_v_3" | translationPipe }}
            </th>
            <td class="date">
              {{ "interface_changelog_page_v_3_date" | translationPipe }}
            </td>
            <td>
              <ul>
                <li>
                  {{
                    "interface_changelog_page_v_3_change_1" | translationPipe
                  }}
                </li>
                <li>
                  {{
                    "interface_changelog_page_v_3_change_2" | translationPipe
                  }}
                </li>
                <li>
                  {{
                    "interface_changelog_page_v_3_change_3" | translationPipe
                  }}
                </li>
                <li>
                  {{
                    "interface_changelog_page_v_3_change_4" | translationPipe
                  }}
                </li>
                <li>
                  {{
                    "interface_changelog_page_v_3_change_5" | translationPipe
                  }}
                  <ul>
                    <li>
                      {{
                        "interface_changelog_page_v_3_change_5_1"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_3_change_5_2"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_3_change_5_3"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_3_change_5_4"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_3_change_5_5"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_3_change_5_6"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_3_change_5_7"
                          | translationPipe
                      }}
                    </li>
                  </ul>
                </li>
                <li>
                  {{
                    "interface_changelog_page_v_3_change_6" | translationPipe
                  }}
                  <ul>
                    <li>
                      {{
                        "interface_changelog_page_v_3_change_6_1"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_3_change_6_2"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_3_change_6_3"
                          | translationPipe
                      }}
                    </li>
                  </ul>
                </li>
                <li>
                  {{
                    "interface_changelog_page_v_3_change_7" | translationPipe
                  }}
                  <ul>
                    <li>
                      {{
                        "interface_changelog_page_v_3_change_7_1"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_3_change_7_2"
                          | translationPipe
                      }}
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
          <!-------FIN Section------>
          <tr>
            <th scope="row">
              {{ "interface_changelog_page_v_2" | translationPipe }}
            </th>
            <td class="date">
              {{ "interface_changelog_page_v_2_date" | translationPipe }}
            </td>
            <td>
              <ul>
                <li>
                  {{
                    "interface_changelog_page_v_2_change_1" | translationPipe
                  }}
                </li>
                <li>
                  {{
                    "interface_changelog_page_v_2_change_2" | translationPipe
                  }}
                </li>
                <li>
                  {{
                    "interface_changelog_page_v_2_change_3" | translationPipe
                  }}
                </li>
                <li>
                  {{
                    "interface_changelog_page_v_2_change_4" | translationPipe
                  }}
                  <ul>
                    <li>
                      {{
                        "interface_changelog_page_v_2_change_4_1"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_2_change_4_2"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_2_change_4_3"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_2_change_4_4"
                          | translationPipe
                      }}
                    </li>
                  </ul>
                </li>
                <li>
                  {{
                    "interface_changelog_page_v_2_change_5" | translationPipe
                  }}
                  <ul>
                    <li>
                      {{
                        "interface_changelog_page_v_2_change_5_1"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_2_change_5_2"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_2_change_5_3"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_2_change_5_4"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_2_change_5_5"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_2_change_5_6"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_2_change_5_7"
                          | translationPipe
                      }}
                    </li>
                  </ul>
                </li>
                <li>
                  {{
                    "interface_changelog_page_v_2_change_6" | translationPipe
                  }}
                  <ul>
                    <li>
                      {{
                        "interface_changelog_page_v_2_change_6_1"
                          | translationPipe
                      }}
                    </li>
                  </ul>
                </li>
              </ul>
            </td>
          </tr>
          <!-------FIN Section------>
          <tr>
            <th scope="row">
              {{ "interface_changelog_page_v_1_1" | translationPipe }}
            </th>
            <td class="date">
              {{ "interface_changelog_page_v_1_1_date" | translationPipe }}
            </td>
            <td>
              <ul>
                <li>
                  {{
                    "interface_changelog_page_v_1_1_change_1" | translationPipe
                  }}
                  <ul>
                    <li>
                      {{
                        "interface_changelog_page_v_1_1_change_1_1"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_1_1_change_1_2"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_1_1_change_1_3"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_1_1_change_1_4"
                          | translationPipe
                      }}
                    </li>
                  </ul>
                </li>
                <li>
                  {{
                    "interface_changelog_page_v_1_1_change_2" | translationPipe
                  }}
                  <ul>
                    <li>
                      {{
                        "interface_changelog_page_v_1_1_change_2_1"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_1_1_change_2_2"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_1_1_change_2_3"
                          | translationPipe
                      }}
                    </li>
                  </ul>
                </li>
                <li>
                  {{
                    "interface_changelog_page_v_1_1_change_3" | translationPipe
                  }}
                  <ul>
                    <li>
                      {{
                        "interface_changelog_page_v_1_1_change_3_1"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_1_1_change_3_2"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_1_1_change_3_3"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_1_1_change_3_4"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_1_1_change_3_5"
                          | translationPipe
                      }}
                    </li>
                    <li>
                      {{
                        "interface_changelog_page_v_1_1_change_3_6"
                          | translationPipe
                      }}
                    </li>
                  </ul>
                </li>
                <li>
                  {{
                    "interface_changelog_page_v_1_1_change_4" | translationPipe
                  }}
                  <ul>
                    <li>
                      {{
                        "interface_changelog_page_v_1_1_change_4_1"
                          | translationPipe
                      }}
                    </li>
                  </ul>
                </li>
                <li>
                  {{
                    "interface_changelog_page_v_1_1_change_5" | translationPipe
                  }}
                </li>
                <li>
                  {{
                    "interface_changelog_page_v_1_1_change_6" | translationPipe
                  }}
                </li>
                <li>
                  {{
                    "interface_changelog_page_v_1_1_change_7" | translationPipe
                  }}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th scope="row">
              {{ "interface_changelog_page_v_1_0" | translationPipe }}
            </th>
            <td class="date">
              {{ "interface_changelog_page_v_1_0_date" | translationPipe }}
            </td>
            <td>
              {{
                "interface_changelog_page_v_1_0_description" | translationPipe
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
